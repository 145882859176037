Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.consumptionTitleText = "Consumption in Cu Ft.";
exports.rateTitleText = "Rate";
exports.cancelButtonText = "Cancel";
exports.submitButtonText = "Save";
exports.editButtonText = "Edit";
exports.settingTitleText = "Settings";
exports.waterSettingsText = "Water settings";
exports.electricitySettiingsText = "Electricity settiings";
exports.serviceChargeText = "Service charge";
// Customizable Area End
