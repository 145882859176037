Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getTenantsApiContentType = "application/json";
exports.getTenantsApiMethod = "GET";
exports.getTenantsApiEndPoint = "bx_block_custom_forms/properties";

exports.showPropertyApiEndPoint = "bx_block_custom_forms/properties";
exports.showPropertyApiMethod = "GET";
exports.showPropertyApiContentType = "application/json";

exports.deleteTenantApiMethod = "DELETE";
exports.deleteTenantApiEndPoint = "bx_block_custom_forms/properties";

exports.backtoPropertiesText = "Back to properties";
exports.addMeterText = "Add meter";
exports.addTeenantText = "Add tenant";
exports.tenantNameText = "Tenant name";
exports.numberOfMetersText = "# of meters";
exports.actionsText = "Actions";
exports.editText = "Edit";
exports.deleteText = "Delete";
exports.serachPlaceholderText = "Search";
// Customizable Area End