import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
export const configJSON = require("../../blocks/landingpage/src/config");

import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
export default class AdminSideBar extends Component {

  render() {
    const isDashboard = ["/DashboardPage"].includes(window.location.pathname)
    const isProperties = ["/PropertiesPage", '/TenantPage', '/MeterPage'].includes(window.location.pathname)
    const isReport = ["/ReportsPage"].includes(window.location.pathname)
    const isSetting = ["/SettingsPage"].includes(window.location.pathname)
    const isSupport = ["/SupportPage"].includes(window.location.pathname)
    
    return (
      <Box sx={styles.sideBarConatiner}>
        <Box sx={styles.sideBarWrapper}>
          <Box sx={styles.listContainer}>
            <Link to="/DashboardPage" style={isDashboard ? styles.activeListItem : styles.listItem}>
              <BarChartRoundedIcon sx={isDashboard ? styles.activeSvg : styles.svg} />
              <Typography style={isDashboard ? styles.activeListItemText : styles.listItemText}>Dashboard</Typography>
            </Link>
            <Link to="/PropertiesPage" style={isProperties ? styles.activeListItem : styles.listItem}>
              <StoreRoundedIcon sx={isProperties ? styles.activeSvg : styles.svg} />
              <Typography style={isProperties ? styles.activeListItemText : styles.listItemText}>Properties</Typography>
            </Link>
            <Link to="/ReportsPage" style={isReport ? styles.activeListItem : styles.listItem}>
              <ContentPasteRoundedIcon sx={isReport ? styles.activeSvg : styles.svg} />
              <Typography style={isReport ? styles.activeListItemText : styles.listItemText}>Reports</Typography>
            </Link>
            <Link to="/SettingsPage" style={isSetting ? styles.activeListItem : styles.listItem}>
              <SettingsOutlinedIcon sx={isSetting ? styles.activeSvg : styles.svg} />
              <Typography style={isSetting ? styles.activeListItemText : styles.listItemText}>Settings</Typography>
            </Link>
            <Link to="/SupportPage" style={isSupport ? styles.activeListItem : styles.listItem}>
              <HelpOutlineOutlinedIcon sx={isSupport ? styles.activeSvg : styles.svg} />
              <Typography style={isSupport ? styles.activeListItemText : styles.listItemText}>Support</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }
}
const styles = {
  sideBarConatiner: {
    position: "relative",
    boxShadow: "0px 2px 8px 0px #00000014",
    zIndex: '100',
  },
  sideBarWrapper: {
    minHeight: "100%",
  },
  listContainer: {
    minHeight: "calc(100% - 40px)",
    padding: "20px",
  },
  listItem: {
    backgroundColor:  "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    width: "293px",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    fleexDirectiion: "row",
    textDecoration: "none",
  },
  svg: {
    margin: '8px 8px 8px 0',
    '> path': {
      fill: '#64748B'
    }
  },
  activeSvg: {
    margin: '8px 8px 8px 0',
    '> path': {
      fill: '#1A7BA4'
    }
  },
  activeListItem: {
    backgroundColor:  "#E8F9FC",
    border: "none",
    borderRadius: "18px",
    width: "293px",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    fleexDirectiion: "row",
    textDecoration: "none",
  },
  listItemText: {
    alignItems: "center",
    flex: 1,
    display: "flex",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
  },
  activeListItemText: {
    alignItems: "center",
    flex: 1,
    display: "flex",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
  },
};
