import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface TenantData {
  name: string,
  start_date: string,
  end_date: string,
  property_id: number,
}

interface MetersData {
  meter_number: string,
  // meter_type: string,
  last_reading: number,
  date_of_last_reading: string,
}

interface S {
  token: string;
  tenantData: TenantData;
  metersData: MetersData[];
  currentProperty: any;
  propertyId: number;
  validate: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddTenantPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddTenantCallId: string = "";
  apiAddMetersCallId: string = "";
  apiShowPropertyCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      tenantData: {
        name: "John Doe",
        start_date: "2022-01-01",
        end_date: "2023-01-01",
        property_id: 1
      },
      metersData: [{
        meter_number: "M-004",
        // meter_type: "electrical",
        last_reading: 150,
        date_of_last_reading: "2024-09-26"
      }],
      currentProperty: {},
      propertyId: 0,
      validate: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiAddTenantCallId) {
          if (responseJson && responseJson.data && responseJson.data.id) {
            if (this.state.metersData.length) {
              await Promise.all(this.state.metersData.map((item: any, index: number) => {
                return this.createMeters(responseJson.data.id, index);              
              }))
            } else {
              this.goTenantsPage();
            }
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map((error: any) => Object.values(error)[0]);
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.apiAddMetersCallId) {
          if (responseJson && responseJson.data && responseJson.data.id) {
            this.goTenantsPage();
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map((error: any) => Object.values(error)[0]);
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.apiShowPropertyCallId) {
          if (
            responseJson &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            this.setState({  currentProperty: responseJson.data.attributes });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  async componentDidMount(): Promise<void> {
    const userRole = await getStorageData('role');

    if (userRole !== 'user') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }

    await this.getToken();
    if (this.state.propertyId !== 0) {
      await this.getPropertyById(this.state.propertyId);
    }
  }

  goPropertiesPage = () => {
    this.props.navigation.navigate("PropertiesPage");
  };

  goTenantsPage = () => {
    this.props.navigation.navigate("TenantPage");
  };

  addNewMeter = () => {};

  createTenant = () => {
    const header = {
      "Content-Type": configJSON.addTenantApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      tenant: this.state.tenantData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddTenantCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addTenantApiEndPoint + `/${this.state.propertyId}/tenants`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addTenantApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  createMeters = (tenantId: string, index: number = 0) => {
    const header = {
      "Content-Type": configJSON.addMetersApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      meter: {
        ...this.state.metersData[index],
        tenant_id: tenantId,
        property_id: this.state.propertyId
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddMetersCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addMetersApiEndPoint + `/${this.state.propertyId}/tenants/${tenantId}/meters`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addMetersApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getPropertyById = (propertyId: number) => {
    const header = {
      "Content-Type": configJSON.showPropertyApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiShowPropertyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showPropertyApiEndPoint + "/" + propertyId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.showPropertyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setTenantData = (valuesData: any) => {
    this.setState({
      tenantData: {
        name: valuesData.tenantName,
        start_date: new Date().toDateString(),
        end_date: new Date().toDateString(),
        property_id: this.state.propertyId
      },
    });
  };

  setMetersData = (valuesData: any[]) => {
    const meterList: any[] = [];
    valuesData?.map(data => {
      meterList.push({
        meter_number: data.meterNumner,
        // meter_type: data.type,
        last_reading: data.lastReading,
        date_of_last_reading: data.lastReadingDate
      })
    })

    this.setState({
      metersData: meterList,
    });
  };

  goToLogInPage = () => {
    this.props.navigation.navigate("LogInPage");
  }

  getToken = async () => {
    let token = await getStorageData("token");
    let propertyId = await getStorageData('propertyId');

    this.setState({ token: token, propertyId: propertyId });
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
