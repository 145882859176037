import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reading {
  id: number;
  meterNumberId: number;
  premiseId: number;
  prevReading: string;
  prevReadingDate: string;
  customer: string;
  property: string;
  address: string[];
  email: string[];
}

interface MeterNumber {
  id: number;
  premiseId: number;
  value: string;
}

interface Premise {
  id: number;
  value: string;
}

interface S {
  error: boolean;
  step: number;
  selectedReading: Reading | null;
  meterNumber: MeterNumber[];
  premise: Premise[];
  readingList: Reading[];
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddReadingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      error: false,
      step: 1,
      selectedReading: null,
      readingList: [
        {
          id: 0,
          meterNumberId: 0,
          premiseId: 0,
          prevReading: "22567",
          prevReadingDate: "",
          customer: "Mr. Arthur Doe",
          property: "Ben-art Realty Corp.",
          address: ["P.O. Box 265", "New Hyde park, NY 11040"],
          email: ["laurie@benartproperties.com", "John@benartproperties.com"],
        },
        {
          id: 1,
          meterNumberId: 1,
          premiseId: 0,
          prevReading: "8834782",
          prevReadingDate: "5/13/2024, 5:43PM",
          customer: "Mr. Arthur Doe 2",
          property: "Ben-art Realty Corp 2.",
          address: [
            "P.O. Box 265",
            "New Hyde park, NY 11040",
            "New Hyde park 2, NY 1740",
            "New Hyde park 3, NY 19040",
          ],
          email: [
            "laurie@benartproperties.com",
            "John@benartproperties.com",
            "Jack@benartproperties.com",
            "Antony@benartproperties.com",
          ],
        },
      ],
      premise: [
        {
          id: 0,
          value: "41-41 Kissena Bvd. flushing",
        },
      ],
      meterNumber: [
        {
          id: 0,
          premiseId: 0,
          value: "100176-41-41 Kissena Bvd. flushing",
        },
        {
          id: 1,
          premiseId: 0,
          value: "104176-46-71 Kissena Bvd.",
        },
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  setStep = (step: number) => {
    this.setState({ step });

    if (step === 1) {
      this.setState({ selectedReading: null });
    }
  };

  setCurrentReading = () => {
    this.props.navigation.navigate("SuccessfullAddingReadingPage");
  };

  setNote = () => {
    this.props.navigation.navigate("SuccessfullNoteSubmitedPage");
  };

  setNoteeError = (error: boolean) => {
    this.setState({ error });
  };

  setSelectedReading = (premise: number, meterNumber: number) => {
    const selected = this.state.readingList[meterNumber]
    this.setState({ selectedReading: selected || null });
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  componentDidMount = async () => {
    const userRole = await getStorageData('role');

    if (userRole !== 'field_representative') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  }
  // Customizable Area End
}
