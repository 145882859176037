import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
export const configJSON = require("../../blocks/landingpage/src/config");

const fieldRepresentativesSVG = require('./view_icon.svg');
const fieldRepresentativesActiveSVG = require('./view_icon_active.svg');
const customersSVG = require('./view_users.svg');
const customersActiveSVG = require('./view_users_active.svg');
const anayticsSVG = require('./image_bar_chart_24px.svg');
const anayticsActiveSVG = require('./image_bar_chart_24px_active.svg');
const settingSVG = require('./image_settings.svg');
const settingActiveSVG = require('./image_active_settings.svg');
const supportSVG = require('./view_icon_ticket.svg');
const supportActiveSVG = require('./view_icon_ticket_active.svg');
export default class AdminConsoleSideBar extends Component {

  render() {
    const isfieldRepresentative = ["/FieldRepresentativesPage"].includes(window.location.pathname)
    const isCustomers = ["/CustomersPage"].includes(window.location.pathname)
    const isCustomersDetails = ["/CustomerDetailsPage"].includes(window.location.pathname)
    const isAdminSettings = ["/admin/settings"].includes(window.location.pathname)
    const isAnalitics = ["/admin/analytics"].includes(window.location.pathname)
    const isAdminSupport = ["/admin/support"].includes(window.location.pathname)
  
    return (
      <Box sx={styles.sideBarConatiner}>
        <Box sx={styles.sideBarWrapper}>
          <Box sx={styles.listContainer}>
            <Link to="/FieldRepresentativesPage" style={isfieldRepresentative ? styles.activeListItem : styles.listItem}>
              <img src={isfieldRepresentative ? fieldRepresentativesActiveSVG : fieldRepresentativesSVG} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography sx={{...styles.lisgItemText, ...(isfieldRepresentative ? styles.activeListItemText : {})}}>Field representatives</Typography>
            </Link>
            <Link to="/CustomersPage" style={isCustomers || isCustomersDetails ? styles.activeListItem : styles.listItem}>
              <img src={isCustomers || isCustomersDetails ? customersActiveSVG : customersSVG} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography sx={{...styles.lisgItemText, ...(isCustomers || isCustomersDetails ? styles.activeListItemText : {})}}>Customers</Typography>
            </Link>
            <Link to="/admin/analytics" style={isAnalitics ? styles.activeListItem : styles.listItem}>
              <img src={isAnalitics ? anayticsActiveSVG : anayticsSVG} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography sx={{...styles.lisgItemText, ...(isAnalitics ? styles.activeListItemText : {})}}>Analytics</Typography>
            </Link>
            <Link to="/admin/support" style={isAdminSupport ? styles.activeListItem : styles.listItem}>
              <img src={isAdminSupport ? supportActiveSVG : supportSVG} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography sx={{...styles.lisgItemText, ...(isAdminSupport ? styles.activeListItemText : {})}}>Supports</Typography>
            </Link>
            <Link to="/admin/settings" style={isAdminSettings ? styles.activeListItem : styles.listItem}>
              <img src={isAdminSettings ? settingActiveSVG : settingSVG} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography sx={{...styles.lisgItemText, ...(isAdminSettings ? styles.activeListItemText : {})}}>Settings</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }
}
const styles = {
  sideBarConatiner: {
    position: "relative",
    boxShadow: "0px 2px 8px 0px #00000014",
    zIndex: '100',
  },
  sideBarWrapper: {
    minHeight: "100%",
  },
  listContainer: {
    minHeight: "calc(100% - 40px)",
    padding: "20px",
  },
  listItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    backgroundColor:  "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    width: "293px",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    fleexDirectiion: "row",
    textDecoration: "none",
  },
  activeListItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    backgroundColor:  "#E8F9FC",
    border: "none",
    borderRadius: "18px",
    width: "293px",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    fleexDirectiion: "row",
    textDecoration: "none",
  },
  lisgItemText: {
    fontFamily: "'Inter', sans-serif",
    alignItems: "center",
    flex: 1,
    display: "flex",
  },
  activeListItemText: {
    fontWeight: 700,
  }
};
