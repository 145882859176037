import React from "react";
// Customizable Area Start
import { Typography, Box } from "@mui/material";
import { mainIcon } from "./assets";
// Customizable Area End

import SuccessfullNoteSubmitedPageController, { Props, configJSON } from "./SuccessfullNoteSubmitedPageController";
// Customizable Area Start
// Customizable Area End
export default class SuccessfullNoteSubmitedPage extends SuccessfullNoteSubmitedPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.successContainer}>        
        <Box sx={webStyle.successBlock}>
          <Box sx={webStyle.addReeadingTitle}>
            <img src={mainIcon} style={webStyle.mainImage} />
            <Typography style={webStyle.successTitle}>{configJSON.successMessageText}</Typography>
            <Typography style={webStyle.successSubTitle}>
                {configJSON.successMessageSubText}
            </Typography>
            <Box sx={webStyle.buttonBlock}>
              <button
                style={webStyle.addNewButton}
                data-test-id="submitBtn"
                type="button"
                onClick={() => { this.goToAddReadingPage() }}
              >
                <Typography sx={webStyle.buttonText}>{configJSON.addNewReadingButtonText}</Typography>
              </button>
              <button
                style={webStyle.backButton}
                data-test-id="cancelBtn"
                type="button"
                onClick={() => { this.goToHomePage() }}
              >
                <Typography sx={webStyle.buttonText}>{configJSON.goToHomeButtonText}</Typography>
              </button>
            </Box>
          </Box>
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  successContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
  },
  successBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  addReeadingTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  successTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  successSubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  backButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  addNewButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  buttonText: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  mainImage: {
    width: "163px",
    height: "154px",    
  },
  buttonBlock: {
    display: "flex",
    flexDirection: "row",
  },
};
// Customizable Area End
