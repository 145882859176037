Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getMetersApiEndPoint = "bx_block_custom_forms/properties";
exports.getMetersApiMethod = "GET";
exports.getMetersApiContentType = "application/json";

exports.showPropertyApiEndPoint = "bx_block_custom_forms/properties";
exports.showPropertyApiMethod = "GET";
exports.showPropertyApiContentType = "application/json";

exports.deleteMeterApiEndPoint = "bx_block_custom_forms/properties";
exports.deleteMeterApiMethod = "DELETE";

exports.searchPlaceholderText = "Search";
exports.backToTenantText = "Back to tenants";
exports.addMeterText = "Add meter";
exports.meterNumberText = "Meter Number";
exports.typeText = "Type";
exports.lastReadingText = "Last reading";
exports.lastReadingDateText = "Date of Last reading";
exports.actionsText = "Actions";
exports.editText = "Edit";
exports.deleteText = "Delete";
// Customizable Area End