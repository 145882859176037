import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip, Radio } from "@mui/material";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import { errorIcon, addIcon, removeIcon, calendarIcon, branch1Icon, branch2Icon, hotIcon, coldIcon } from "./assets";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Customizable Area End

import AddTenantPageController, {
  Props,
  configJSON,
} from "./AddTenantPageController";
// Customizable Area Start
// Customizable Area End
export default class AddTenantPage extends AddTenantPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  questionSchema = () => {
    return Yup.object().shape({
      tenantName: Yup.string().required("Tenant Name is required"),
      meters: Yup.array()
        .of(
          Yup.object().shape({
            type: Yup.string(),
            meterNumner: Yup.string(),
            lastReading: Yup.string(),
            lastReadingDate: Yup.string(),
          })
        )
        .notRequired(),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addTenantsContainer}>
        <Box sx={webStyle.addTenantsBlock}>
          <Box sx={webStyle.addTenantFormContainer}>
            <Box>
              <Typography sx={webStyle.formTitle}>{this.state.currentProperty.property_name}</Typography>
              <Typography sx={webStyle.formSubTitle}>
                {this.state.currentProperty.service_address}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                tenantName: "",
                meters: [
                  {
                    type: "Electrical",
                    waterType: "",
                    meterNumner: "",
                    lastReading: "",
                    lastReadingDate: "",
                  },
                ],
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
                this.createTenant();
              }}
              data-test-id="addTenantForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.mainFieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"tenantName"}>
                        {configJSON.tenantNameText}
                      </label>
                      <input
                        style={{...webStyle.inputElemnt, ...(this.state.validate && errors.tenantName ? webStyle.inputElementError : {})}}
                        data-test-id="tenantName"
                        type="text"
                        onChange={(event) => {
                          setFieldValue("tenantName", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.tenantName && (
                        <Tooltip
                          open={Boolean(errors.tenantName)}
                          title={errors.tenantName}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <FieldArray name="meters">
                      {(arrayHelpers: any) => (
                        <>
                          {values.meters.map((meter, index) => (
                            <Box sx={webStyle.meterBlcok}>
                              <Box sx={webStyle.meterTitle}>
                                <Typography sx={webStyle.meterTitleLabel}>
                                  {configJSON.meterTitleText}
                                  {index + 1}
                                </Typography>
                                <button
                                  style={webStyle.removeMeeterButton}
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <img
                                    src={removeIcon}
                                    alt=""
                                    style={webStyle.btnIcon}
                                  />
                                </button>
                              </Box>
                              <Box sx={webStyle.arrayFieldControl}>
                                <label
                                  style={webStyle.radioButtonTitleLabel}
                                  htmlFor={`meters.${index}.type`}
                                >
                                  {configJSON.typeText}
                                </label>
                                <Box sx={webStyle.radioButtnBlock}>
                                  <Radio
                                    value="Electrical"
                                    checked={values.meters[index].type === 'Electrical'}
                                    sx={webStyle.radioButton()}
                                    data-test-id={`meters.${index}.type.electrical`}
                                    name={`meterType.${index}`}
                                    onChange={(event) => {
                                      const element = event.currentTarget as HTMLInputElement;
                                      const value = element.value;
                                      setFieldValue(`meters.${index}.type`, value);
                                    }}
                                  />
                                  <label
                                    style={webStyle.radioButtonLabel}
                                    htmlFor="electrical"
                                  >
                                    {configJSON.electricalTypeText}
                                  </label>
                                </Box>
                                <Box sx={webStyle.radioButtnBlock}>
                                  <Radio
                                    value="Water"
                                    checked={values.meters[index].type === 'Water'}
                                    sx={webStyle.radioButton()}
                                    data-test-id={`meters.${index}.type`}
                                    name={`meterType.${index}`}
                                    onChange={(event) => {
                                      const element = event.currentTarget as HTMLInputElement;
                                      const value = element.value;
                                      setFieldValue(`meters.${index}.type`, value);
                                    }}
                                  />
                                  <label
                                    style={webStyle.radioButtonLabel}
                                    htmlFor="meter"
                                  >
                                    {configJSON.meterTypeText}
                                  </label>
                                </Box>
                              </Box>
                              {values.meters[index].type === 'Water' && (
                                <Box sx={{ position: 'relative', padding: '1px 0 0 28px' }}>
                                  <img
                                    src={branch1Icon}
                                    alt="Divider"
                                    className="custom-divider"
                                    style={{ position: 'absolute', left: '9px',top:'-18px', height: '32px' }}
                                  />    
                                  <Box sx={webStyle.radioButtonBlock}>
                                    <Radio
                                      value="Hot"
                                      checked={values.meters[index].waterType === 'Hot'}
                                      sx={webStyle.radioButton(true)}
                                      data-test-id={`meters.${index}.waterType.hot`}
                                      id={`meters.${index}.waterType.hot`}
                                      name={`meters.${index}.waterType`}
                                      onChange={(event) => {
                                        setFieldValue(`meters.${index}.waterType`, event.currentTarget.value);
                                      }}
                                    />
                                    <label style={{...webStyle.radioButtonLabel,fontSize:"14px",marginLeft:"8px"}} htmlFor={`meters.${index}.waterType.hot`}>
                                      <img
                                        src={hotIcon} 
                                        alt="Hot"
                                        width="16" 
                                        height="16"
                                        style={{ marginRight: '4px' }}
                                      />
                                      Hot
                                    </label>
                                  </Box>
                                  <img
                                    src={branch2Icon}
                                    alt="Divider"
                                    className="custom-divider"
                                    style={{ position: 'absolute', left: '9px',top:"7px" }}
                                  />  
                                  <Box sx={webStyle.radioButtonBlock}>
                                    <Radio
                                      value="Cold"
                                      checked={values.meters[index].waterType === 'Cold'}
                                      sx={webStyle.radioButton(true)}
                                      data-test-id={`meters.${index}.waterType.cold`}
                                      id={`meters.${index}.waterType.cold`}
                                      name={`meters.${index}.waterType`}
                                      onChange={(event) => {
                                        setFieldValue(`meters.${index}.waterType`, event.currentTarget.value);
                                      }}
                                    />
                                    <label style={{...webStyle.radioButtonLabel,fontSize:"14px",marginLeft:"8px"}} htmlFor={`meters.${index}.waterType.hot`}>
                                      <img
                                        src={coldIcon} 
                                        alt="Cold"
                                        width="16" 
                                        height="16"
                                        style={{ marginRight: '4px' }}
                                      />
                                      Cold
                                    </label>
                                  </Box>
                                </Box>
                              )}
                              <Box sx={webStyle.arrayFieldControl}>
                                <label
                                  style={webStyle.inputLabel}
                                  htmlFor={`meters.${index}.meterNumner`}
                                >
                                  {configJSON.meterNumberText}
                                </label>
                                <input
                                  style={webStyle.inputElemnt}
                                  data-test-id={`meters.${index}.meterNumner`}
                                  type="text"
                                  onChange={(event) => {
                                    setFieldValue(`meters.${index}.meterNumner`, event.target.value);
                                  }}
                                />
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.lastReading`}
                                  >
                                    {configJSON.lastReadingText}
                                  </label>
                                  <input
                                    style={webStyle.inputElemnt}
                                    data-test-id={`meters.${index}.lastReading`}
                                    type="text"
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.lastReading`, event.target.value);
                                    }}
                                  />
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.lastReadingDate`}
                                  >
                                    {configJSON.lastReadingDateText}
                                  </label>
                                  {/* <DatePicker /> */}
                                  <input
                                    style={webStyle.inputElemnt}
                                    data-test-id={`meters.${index}.lastReadingDate`}
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.lastReadingDate`, event.target.value);
                                    }}
                                  />
                                  <img
                                    src={calendarIcon}
                                    alt=""
                                    style={webStyle.calendarIcon}
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Box sx={webStyle.fieldControl}>
                              <button
                                style={webStyle.addNewMeterButton}
                                type="button"
                                onClick={() => {
                                  arrayHelpers.push({
                                    // type: "",
                                    meterNumner: "",
                                    lastReading: "",
                                    lastReadingDate: "",
                                  });
                                }}
                              >
                                <img
                                  src={addIcon}
                                  alt=""
                                  style={webStyle.btnIcon}
                                />
                                <Typography style={webStyle.typographyTitleText}>
                                  {configJSON.addNewMeterText}
                                </Typography>
                              </button>
                            </Box>
                          </>
                        )}
                      </FieldArray>
                      <Box sx={webStyle.fieldControlGroup}>
                        <button
                          style={webStyle.cancelButton}
                          data-test-id="cancelBtn"
                          type="button"
                          onClick={() => {
                            this.goTenantsPage();
                          }}
                        >
                          {configJSON.cancelBtnText}
                        </button>
                        <button
                          style={webStyle.submitButton}
                          data-test-id="submitBtn"
                          type="button"
                          onClick={() => {
                            this.setTenantData({ tenantName: values?.tenantName });
                            this.setMetersData(values?.meters);
                            this.setValidate(true);
                            if (Object.keys(errors).length == 0) {
                              handleSubmit();
                            }
                            // this.goPropertieesPage();
                          }}
                        >
                          {configJSON.saveBtnText}
                        </button>
                      </Box>
                    </Box>
                    {console.log({ values })}
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
        //Merge Engine End DefaultContainer
        // Customizable Area Start
      );
    }
  }
  // Customizable Area Start
const tooltipStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

  const webStyle = {
    addTenantsContainer: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#F8FAFC",
    },
    addTenantsBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    addTenantFormContainer: {
      maxWidth: "600px",
      width: "80%",
      margin: '40px auto 0',
    },
    formContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    formTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "10px",
      wordBreak: 'break-word',
    },
    formSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
      wordBreak: 'break-word',
    },
    fieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px",
      position: "relative",
    },
    mainFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    arrayFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    fieldControlGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    fieldCheckboxControl: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      margin: "10px",
      position: "relative",
    },
    switchTitle: {
      alignItems: "center",
      display: "flex",
    },
    radioButtonTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#64748B",
      margin: "10px 0px",
      alignItems: "center",
      display: "flex",
    },
    inputLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      marginBottom: "5px",
      alignItems: "center",
      display: "flex",
    },
    radioButtonLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      alignItems: "center",
      display: "flex",
      marginLeft: '8px',
    },
    radioButtnBlock: {
      display: "flex",
      flexDirection: "row",
      margin: "10px 0px",
      alignItems: 'center',
    },
    numberInputElemnt: {
      height: "56px",
      padding: "10px",
      border: "none",
      "-moz-appearance": "textfield",
      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      width: "34%",
      textAlign: "center" as const,
    },
    inputElemnt: {
      height: "56px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
    },
    inputElementError: {
      border: "1px solid #F87171",
    },
    radioButtonBlock: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '11px',
    },
    radioButton: (isSmall?: boolean) => ({
      padding: 0,
      width: isSmall ? '16px' : '20px',
      height: isSmall ? '16px' : '20px',
      '& .MuiSvgIcon-root': {
        color: '#64748B',
        width: isSmall ? '20px' : '24px',
        height: isSmall ? '20px' : '24px',
      },
      '&.Mui-checked': {
        backgroundColor: '#1A7BA4',
        '& .MuiSvgIcon-root': {
          width: isSmall ? '16px' : '20px',
          height: isSmall ? '16px' : '20px',
        },
        '& .MuiSvgIcon-root:first-of-type': {
          color: '#1A7BA4',
        },
        '& .MuiSvgIcon-root:nth-of-type(2)': {
          color: '#FFFFFF',
        }
      },
    }),
    errorIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 16,
    },
    numberFieldControlIcon: {
      width: "24px",
      height: "24px",
    },
    numberFieldControlButton: {
      color: "#1A7BA41A",
      border: "none",
      width: "33%",
    },
    cancelButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginTop: "30px",
      height: "44px",
      borderRadius: "8px",
      border: "1px solid #1A7BA4",
      background: "#FFFFFF",
      color: "#1A7BA4",
      padding: "0px 16px",
    },
    submitButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "30px 0 0 16px",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 16px",
    },
    meterToReadBlcok: {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
    },
    meterBlcok: {
      border: "1px solid #1A7BA44D",
      backgroundColor: "#1A7BA40F",
      padding: "16px",
      borderRadius: "12px",
      margin: "20px 0px",
    },
    meterTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    meterTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "18px",
      fontWeight: 700,
      lineheight: "24px",
      color: "#0F172A",
    },
    addNewMeterButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "15px",
      height: "56px",
      borderRadius: "8px",
      border: "none",
      background: "inherit",
      color: "#1A7BA4",
      padding: "0px 20px",
      flexDirection: "row" as const,
      display: "flex",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    removeMeeterButton: {
      height: "36px",
      width: "46px",
      border: "none",
      backgroundColor: "inherit",
    },
    typographyTitleText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
    },
    btnIcon: {
      width: "24px",
      height: "24px",
    },
    calendarIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 16,
    },
  };
// Customizable Area End
