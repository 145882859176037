import React, {useState } from 'react';
import { Typography, 
  Box, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  TextField, 
  IconButton, 
  InputAdornment,
  Tooltip
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch, { SwitchProps } from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  noPropertieisIcon,
  errorIcon,
  removeIcon
} from "./assets";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

  export interface Props {
    propertiesList: any;
    searchProperties: (event: React.ChangeEvent<HTMLInputElement>) => void;
    filterProperties: (event: React.ChangeEvent<HTMLInputElement>) => void;
    addTenant: (event: any,value:number) => void;
  }

  interface PropertyDialogProps {
    isEdit: boolean;
  }

  import CloseRoundedIcon from '@mui/icons-material/Close';

  const itemsPerPage = 10;

  export const CustomerProperties = ({propertiesList,searchProperties,filterProperties,addTenant}:Props) => {
  
    const [addPropertyDialoge , setaddPropertyDialoge ] = useState<PropertyDialogProps | null>(null);
    const [deleteButtonDialoge , setDeleteButtonDialoge ] = useState(false);
    const [validate , setValidate ] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(propertiesList.length / itemsPerPage) || 1;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;


    const handleNextPage = () => {
      if (currentPage < Math.ceil(propertiesList.length / itemsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };

    const handlePrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    }


   const questionSchema = () => {
      return Yup.object().shape({
        propertyName: Yup.string().required("Property Name is required"),
        serviceAddress: Yup.string().required("Service Address is required"),
        meterLocation: Yup.string().required("Meter Location is required"),
        buildingManager: Yup.string().required("Building Manager is required"),
        phone: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(8, "Must be more then 8 digits")
          .max(12, "Must be less then 512digits")
          .required("Phone Number is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Mail is required"),
        meterToRead: Yup.number().required("Meter of Read is required"),
        getInvoice: Yup.boolean().required("Required"),
        addServiceCharge: Yup.boolean().required("Required"),
      });
    };


    const IOSSwitch = styled((props: SwitchProps) => (
      <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor: "#1A7BA4",
            opacity: 1,
            border: 0,
            ...theme.applyStyles("dark", {
              backgroundColor: "#2ECA45",
            }),
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#33cf4d",
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color: theme.palette.grey[100],
          ...theme.applyStyles("dark", {
            color: theme.palette.grey[600],
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.7,
          ...theme.applyStyles("dark", {
            opacity: 0.3,
          }),
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: "#E9E9EA",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
        ...theme.applyStyles("dark", {
          backgroundColor: "#39393D",
        }),
      },
    }));


    return (
      <Box sx={webStyle.propertiesContainer}>
      {propertiesList.length === 0 ? (
        <Box sx={webStyle.noPropertyBlock}>
          <Box sx={webStyle.noPropertyBlockTitle}>
            <img src={noPropertieisIcon} style={webStyle.mainImage} />
            <Typography style={webStyle.noPropertyTitle}>No properties</Typography>
            <Typography style={webStyle.noPropertySubTitle}>
            Click on the button below to start adding your properties
            </Typography>
            <Box sx={webStyle.buttonBlock}>
              <button
                style={webStyle.addNewButton}
                data-test-id="submitBtn"
                type="button"
                onClick={()=>setaddPropertyDialoge({ isEdit: false })}
              >
                <Typography sx={webStyle.buttonText}>Add property</Typography>
              </button>
            </Box>
          </Box>
        </Box>
      ) : ( 
        <>
        <Box sx={webStyle.propertiesTableHeader}>
          <Box sx={webStyle.searchContainer}>
            <Box sx={webStyle.fieldControl}>
              <input
                style={{...webStyle.inputElemnt, ...webStyle.searchElement}}
                data-test-id="search"
                type={"text"}
                placeholder= "Search"
                onChange={(event) => {searchProperties(event) }}
              />
              <img
                src={searchIcon}
                alt=""
                style={webStyle.searchIcon}
                />
            </Box>
          </Box>
          <Box sx={webStyle.actionContainer}>
            <button style={webStyle.addPropertiesButton}
            onClick={()=>setaddPropertyDialoge({ isEdit: false })}
             >
              <img
                src={addIcon}
                alt=""
                style={webStyle.btnIcon}
              />
              <Typography sx={webStyle.buttonText}>Add property</Typography>
            </button>
          </Box>
        </Box>
        <Box>
        <Typography fontFamily={"'Inter', sans-serif"} fontSize={"16px"} color={"#334155"} fontWeight={500} marginBottom={"16px"}>{propertiesList.length} results</Typography>
        </Box>
        <Box sx={{ minHeight: totalPages > 1 ? "674px" : "unset" }}>
          <Box sx={webStyle.propertisTable}>
            <Box sx={webStyle.propertisTableHead}>
              <Typography style={webStyle.headerTitle}>Property Name</Typography>
              <Typography style={webStyle.headerTitle}>Address</Typography>
              <Typography style={webStyle.headerShortTitle}># of Tenants</Typography>
              <Typography style={webStyle.headerShortTitle}># of Meters</Typography>
              <Typography style={webStyle.headerTitleAction}>Actions</Typography>
            </Box>
            {propertiesList.slice(indexOfFirstItem, indexOfLastItem).map((proprty: { id: number; propertysName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; propertysAddress: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; countTenant: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; countMeter: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
              <Box sx={webStyle.propertisTableBody} key={proprty.id}>
                <Box sx={webStyle.propertyItem}>{proprty.propertysName}</Box>
                <Box sx={webStyle.propertyItem}>{proprty.propertysAddress}</Box>
                <Box sx={webStyle.propertyShortItem}>{proprty.countTenant}</Box>
                <Box sx={webStyle.propertyShortItem}>{proprty.countMeter}</Box>
                <Box sx={webStyle.propertyItemAction}>
                  <button style={webStyle.addTenantButton} 
                onClick={(event) => addTenant(event,proprty.id) }
                  >
                    <VisibilityOutlinedIcon sx={{ ...webStyle.actionButton, color: '#FFFFFF' }} />
                    <Typography style={webStyle.typographyText}>View details</Typography>
                  </button>
                  <button style={webStyle.editButton}
                  onClick={() => setaddPropertyDialoge({ isEdit: true })}
                  >
                    <img
                      src={editIcon}
                      alt=""
                      style={webStyle.actionButton}
                    />
                    <Typography style={webStyle.typographyText}>Edit</Typography>
                  </button>
                  <button style={webStyle.deleteButton} 
                  onClick={() => setDeleteButtonDialoge(true)}
                  >
                    <img
                      src={deleteIcon}
                      alt=""
                      style={webStyle.actionButton}
                    />
                    <Typography style={webStyle.typographyText}>Delete</Typography>
                  </button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {totalPages > 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "5%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Previous Button */}
                  <button
                    onClick={handlePrevPage}
                    data-test-id="previous-button"
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: "4px",
                    }}
                    disabled={currentPage === 1}
                  >
                    <KeyboardArrowLeftRoundedIcon
                      sx={
                        currentPage === 1
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>

                  {totalPages <= 3 ? (
                    [...Array(totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        data-test-id={"page-button"}
                        style={{
                          marginRight: "4px",
                          width:
                            currentPage === page + 1
                              ? "24px"
                              : "16px",
                          height: "24px",
                          backgroundColor:
                            currentPage === page + 1
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            currentPage === page + 1
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            currentPage !== page + 1
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          fontWeight:
                            currentPage !== page + 1 ? 400 : 700,
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {currentPage !== 1 && (
                        <button
                          data-test-id="ellipsis"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() => handlePageChange(1)}
                        >
                          1
                        </button>
                      )}

                      {currentPage > 3 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {currentPage > 2 && (
                        <button
                          data-test-id="middle-page"
                          style={{
                            marginRight: "4px",
                            width: "24px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            handlePageChange(currentPage - 1)
                          }
                        >
                          {currentPage - 1}
                        </button>
                      )}
                      <button
                        data-test-id="middle-page2"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "#DBF5FF",
                          color: "#1A7BA4",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 700,
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          handlePageChange(currentPage)
                        }
                      >
                        {currentPage}
                      </button>

                      {currentPage < totalPages - 1 && (
                        <button
                          data-test-id="middle-page3"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            color: "#64748B",
                            backgroundColor: "inherit",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            handlePageChange(currentPage + 1)
                          }
                        >
                          {currentPage + 1}
                        </button>
                      )}

                      {currentPage < totalPages - 2 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {currentPage !== totalPages && (
                        <button
                          data-test-id="last-page"
                          style={{
                            marginRight: "4px",
                            width:
                              currentPage === totalPages
                                ? "24px"
                                : "16px",
                            height: "24px",
                            backgroundColor:
                              currentPage === totalPages
                                ? "#DBF5FF"
                                : "inherit",
                            color:
                              currentPage === totalPages
                                ? "#1A7BA4"
                                : "#64748B",
                            border: "none",
                            textDecoration:
                              currentPage !== totalPages
                                ? "underline"
                                : "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                            fontWeight:
                              currentPage !== totalPages
                                ? 400
                                : 700,
                          }}
                          onClick={() =>
                            handlePageChange(totalPages)
                          }
                        >
                          {totalPages}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    data-test-id="next-page"
                    onClick={() => handleNextPage()}
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    disabled={currentPage === totalPages}
                  >
                    <KeyboardArrowRightRoundedIcon
                      sx={
                        currentPage === totalPages
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>
                </Box>

                <Box sx={{ marginRight: "10px" }}>
                  <Typography
                    fontFamily={'"Nunito Sans", sans-serif'}
                    fontSize={"12px"}
                    color={"grey"}
                  >
                    {indexOfFirstItem + 1}-{indexOfLastItem} of{" "}
                    {propertiesList.length} results
                  </Typography>
                </Box>
              </Box>
            )}



        <Dialog 
        open={!!addPropertyDialoge}
        onClose={()=>setaddPropertyDialoge(null)}
        maxWidth="sm" 
        fullWidth 
        PaperProps={{
          style: {
            borderRadius: '8px',
            padding: '32px',
            maxHeight: 'calc(100% - 120px)',
          },
        }}
      >
        <DialogTitle style={{ padding: '0 0 32px', position: 'relative' }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            {`${addPropertyDialoge?.isEdit ? 'Edit' : 'Add'} property`}
          </Typography>
          <IconButton
            onClick={()=>{setaddPropertyDialoge(null)}}
            style={{ position: 'absolute', right: '0', top: '-8px', width: '56px', height: '56px', backgroundColor: '#F8FAFC', borderRadius: '52px' }}
          >
            <CloseRoundedIcon sx={{ width: '24px', height: '24px', color: '#64748B' }} />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ marginBottom: '22px' }} />
        <DialogContent sx={{ padding: 0, overflowY: 'unset' }}>
        <Box sx={webStyle.addPropertiesContainer}>
        <Box sx={webStyle.addPropertiesBlock}>
          <Box sx={webStyle.addPropertyFormContainer}>
            <Formik
              initialValues={{
                propertyName: propertiesList
                  ? propertiesList.propertysName
                  : "",
                serviceAddress: propertiesList
                  ? propertiesList.propertysAddress
                  : "",
                meterLocation: "",
                buildingManager: "",
                phone: "",
                email: "",
                meterToRead: "0",
                readingPeriod:  "",
                startReadingFrom:  "",
                reportTo: "",
                getInvoice: true,
                addServiceCharge: true,
              }}
              validateOnChange={true}
              validationSchema={questionSchema}
              onSubmit={() => {
                setValidate(true);
              }}
              data-test-id="addPropertyForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form style={{ margin: 0 }}>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl1}>
                      <label
                        style={webStyle.inputLabel}
                        htmlFor={"propertyName"}
                      >
                        Property name
                      </label>
                      <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.propertyName ? webStyle.inputElementError : {})}}
                        data-test-id="propertyName"
                        type="text"
                        value={values?.propertyName}
                        onChange={(event) => {
                          setFieldValue("propertyName", event.target.value);
                        }}
                      />
                      {validate && errors.propertyName && (
                        <Tooltip
                          open={Boolean(errors.propertyName)}
                          title={errors.propertyName}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl1}>
                      <label
                        style={webStyle.inputLabel}
                        htmlFor={"serviceAddress"}
                      >
                        Service address
                      </label>
                      <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.serviceAddress ? webStyle.inputElementError : {})}}
                        data-test-id="serviceAddress"
                        type="text"
                        value={values?.serviceAddress}
                        onChange={(event) => {
                          setFieldValue("serviceAddress", event.target.value);
                        }}
                      />
                      {validate && errors.serviceAddress && (
                        <Tooltip
                          open={Boolean(errors.serviceAddress)}
                          title={errors.serviceAddress}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                           src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl1}>
                      <label
                        style={webStyle.inputLabel}
                        htmlFor={"meterLocation"}
                      >
                        Meter location
                      </label>
                      <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.meterLocation ? webStyle.inputElementError : {})}}
                        data-test-id="meterLocation"
                        type="text"
                        onChange={(event) => {
                          setFieldValue("meterLocation", event.target.value);
                        }}
                      />
                      {validate && errors.meterLocation && (
                        <Tooltip
                          open={Boolean(errors.meterLocation)}
                          title={errors.meterLocation}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                           src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl1}>
                      <label
                        style={webStyle.inputLabel}
                        htmlFor={"buildingManager"}
                      >
                        Building manager / Supt's name
                      </label>
                      <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.buildingManager ? webStyle.inputElementError : {})}}
                        data-test-id="buildingManager"
                        type={"text"}
                        onChange={(event) => {
                          setFieldValue("buildingManager", event.target.value);
                        }}
                      />
                      {validate && errors.buildingManager && (
                        <Tooltip
                          open={Boolean(errors.buildingManager)}
                          title={errors.buildingManager}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                           src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <Box sx={webStyle.fieldControl1}>
                        <label style={webStyle.inputLabel} htmlFor={"phone"}>
                          Phone number
                        </label>
                        <input
                          style={{...webStyle.inputElemnt1, ...(validate && errors.phone ? webStyle.inputElementError : {})}}
                          data-test-id="phone"
                          type={"text"}
                          onChange={(event) => {
                            setFieldValue(
                              "phone",
                              event.target.value.replace(/\D/g, "")
                            );
                          }}
                        />
                        {validate && errors.phone && (
                          <Tooltip
                            open={Boolean(errors.phone)}
                            title={errors.phone}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img
                             src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                      </Box>
                      <Box sx={webStyle.fieldControl1}>
                        <label style={webStyle.inputLabel} htmlFor={"email"}>
                         Email
                        </label>
                        <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.email ? webStyle.inputElementError : {})}}
                          data-test-id="email"
                          type={"email"}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                        />
                        {validate && errors.email && (
                          <Tooltip
                            open={Boolean(errors.email)}
                            title={errors.email}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img
                             src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <Box sx={webStyle.fieldControl1}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"meterToRead"}
                        >
                          # of meters to read
                        </label>
                        <Box sx={webStyle.meterToReadBlcok}>
                          <button
                            style={{...webStyle.numberFieldControlButton, ...webStyle.numberFieldControlButtonLeft}}
                            type="button"
                            data-test-id="meterToReadMinus"
                            onClick={() => {
                              setFieldValue(
                                "meterToRead",
                                (Number(values?.meterToRead) - 1).toString()
                              );
                            }}
                          >
                            <img
                              src={removeIcon}
                              style={webStyle.numberFieldControlIcon}
                            />
                          </button>
                          <input
                            style={webStyle.numberInputElemnt}
                            data-test-id="meterToRead"
                            type="text"
                            min={0}
                            value={values?.meterToRead || "0"}
                          />
                          <button
                            style={{...webStyle.numberFieldControlButton, ...webStyle.numberFieldControlButtonRight}}
                            type="button"
                            data-test-id="meterToReadPlus"
                            onClick={() => {
                              setFieldValue(
                                "meterToRead",
                                (Number(values?.meterToRead) + 1).toString()
                              );
                            }}
                          >
                            <AddRoundedIcon sx={webStyle.numberFieldControlIcon} />
                          </button>
                        </Box>
                      </Box>
                      <Box sx={webStyle.fieldControl1}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"readingPeriod"}
                        >
                          Reading period
                        </label>
                        <Select
                          value={0}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          data-test-id="readingPeriod"
                          sx={webStyle.selectInput}
                        >
                          <MenuItem sx={webStyle.selectItem} value={0}>Monthly</MenuItem>
                        </Select>
                      </Box>
                      <Box sx={webStyle.fieldControl1}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"startReadingFrom"}
                        >
                          Start reading from 
                        </label>
                        <Select
                          value={1}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          data-test-id="startReadingFrom"
                          sx={webStyle.selectInput}
                        >
                          <MenuItem sx={webStyle.selectItem} value={1}>January</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                    <Box sx={webStyle.fieldControl1}>
                      <label style={webStyle.inputLabel} htmlFor={"reportTo"}>
                       Receive report by
                      </label>
                      <Select
                        value={0}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        data-test-id="reportTo"
                        sx={webStyle.selectInput}
                      >
                        <MenuItem sx={webStyle.selectItem} value={0}>Email</MenuItem>
                      </Select>
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Typography style={webStyle.switchTitle}>
                      Get invoice for my tenants
                      </Typography>
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        data-test-id="getInvoice"
                      />
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Typography style={webStyle.switchTitle}>
                      Add service charge to invoice
                      </Typography>
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        data-test-id="addServiceCharge"
                      />
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <button
                        style={webStyle.cancelButton}
                        data-test-id="cancelBtn"
                        type="button"
                        onClick={() => {
                          setaddPropertyDialoge(null)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={webStyle.submitButton}
                        data-test-id="submitBtn"
                        type="button"
                        onClick={() => {
                          // setFormDate(values);
                          setValidate(true);
                          if (Object.keys(errors).length == 0) {
                            handleSubmit();
                          }
                        }}
                      >
                       Save
                      </button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
        </DialogContent>
      </Dialog>

        <Dialog
              open={deleteButtonDialoge}
              onClose={()=>setDeleteButtonDialoge(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  width: "633px", 
                  height: "240px", 
                  padding: "40px 32px 32px 32px", 
                  gap: "10px", 
                  borderRadius: "16px", 
                  fontFamily:"'Inter', sans-serif",
                },
              }}
            >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "600", paddingBottom: "8px",fontFamily:"'Inter', sans-serif", fontSize:'20px',whiteSpace:"nowrap"}}
        >
          Are you sure you want to delete this account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#64748B", paddingBottom: "16px",fontFamily:"'Inter', sans-serif",fontSize:"18px"}}
          >
            This action can’t be undone and all the data stored in this account
            won’t be accessible anymore.
          </DialogContentText>
          <Divider /> 
        </DialogContent>
        <DialogActions style={{ padding: "16px" }}>
          <Button
            onClick={()=>setDeleteButtonDialoge(false)}
            variant="outlined"
            sx={webStyle.cancelButton}
          >
            Cancel
          </Button>
          <Button
            // onClick={this.deleteReport}
            sx={webStyle.confirmDelete}
            startIcon={<DeleteIcon />} 
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      </>
      )}
      </Box>
    );
  };

  const tooltipStyles = {
    tooltip: {
      sx: {
        color: '#FFFFFF',
        fontFamily: "'Inter', sans-serif",
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: '400',
        padding: '8px 12px',
        backgroundColor: '#1E293B',
        borderRadius: '8px',
        width: 'fit-content',
        maxWidth: '480px',
        '& .MuiTooltip-arrow': {
          color: '#1E293B',
        },
      }
    }
  };

  const webStyle = {
    propertiesContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#F8FAFC",
    },
    propertiesTableHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      margin: "20px 0px",
    },
    searchContainer: {
      display: "flex",
      flexDirection: "row",
      width: "340px",
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
    },
    propertisTable: {
      border: "1px solid #CBD5E1",
      borderRadius: "12px",
    },
    propertisTableHead: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 10px",
      backgroundColor: '#F8FAFC',
      borderRadius: '12px 12px 0 0',
    },
    propertisTableBody: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 10px",
      borderTop: "1px solid #CBD5E1",
      backgroundColor: '#FFFFFF',
      '&:last-of-type': {
        borderRadius: '0 0 12px 12px',
      }
    },
    fieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: "10px",
      marginBottom: "10px",
      position: "relative",
    },
    inputElemnt: {
      height: "44px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    searchElement: {
      padding: "10px 40px 10px 10px",
    },
    searchIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 10,
      right: 16,
    },
    btnIcon: {
      width: "24px",
      height: "24px",
    },
    filterButton: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#1A7BA4",
      border: "none",
      backgroundColor: "#F8FAFC",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 20px",
      padding: "6px 10px",
    },
    addPropertiesButton: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#FFFFFF",
      border: "none",
      borderRadius: "8px",
      backgroundColor: "#1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 16px",
      gap: '8px',
    },
    headerTitle: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "calc(30% - 80px)",
      margin: "12px 0px",
    },
    headerShortTitle: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "calc(20% - 80px)",
      margin: "12px 0px",
    },
    headerTitleAction: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "320px",
      margin: "12px 0px",
    },
    addTenantButton: {
      backgroundColor: "#1A7BA4",
      color: "#FFFFFF",
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 8px",
      borderRadius: "7px",
      border: "none",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    editButton: {
      backgroundColor: "#FFFFFF",
      color: "#1A7BA4",
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 8px",
      borderRadius: "7px",
      border: "1px solid #1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    deleteButton: {
      backgroundColor: "#FFFFFF",
      color: "#DC2626",
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 8px",
      borderRadius: "7px",
      border: "1px solid #DC2626",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    propertyItem: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "calc(30% - 80px)",
      margin: "13px 0px",
      alignItems: "center",
      display: "flex",
    },
    propertyShortItem: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "calc(20% - 80px)",
      margin: "13px 0px",
      alignItems: "center",
      display: "flex",
    },
    propertyItemAction: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "320px",
      display: "flex",
      flexDirection: "row",
      margin: "13px 0px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    noPropertyBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      maxWidth: "600px",
      width: "80%",
    },
    noPropertyBlockTitle: {
      margin: "80px 10px",
      textAlign: "center",
    },
    noPropertyTitle: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "15px",
      marginTop: "50px",
    },
    noPropertySubTitle: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
    },
    addNewButton: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "15px auto",
      height: "56px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 20px",
      flexDirection: "row" as const,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "430px",
    },
    buttonText: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    mainImage: {
      width: "163px",
      height: "154px",    
    },
    buttonBlock: {
      display: "flex",
      flexDirection: "row",
    },
    typographyText: {    
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
    },

    addPropertiesContainer: {
      display: "flex",
      flexDirection: "row",
      fontFamily: "'Inter', sans-serif",
    },
    addPropertiesBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    addPropertiesBlockTitle: {
      margin: "10px",
    },
    addPropertyFormContainer: {
      maxWidth: "700px",
      width: '100%',
    },
    formContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    formTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "6px",
    },
    formSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
    },

    select: {
      fontFamily: "'Inter', sans-serif",
      '& .MuiSelect-select': {
        fontFamily: "'Inter', sans-serif",
      },
    },
    selectItem: {
      fontFamily: "'Inter', sans-serif",
    },
    selectInput: {
      fontFamily: "'Inter', sans-serif",
      backgroundColor: "#FFFFFF",
      "& fieldset": {
        border: "1px solid #CBD5E1",
      },
      "& fieldset:hover": {
        border: "1px solid #CBD5E1 !important",
      },
    },
    fieldControlGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: '16px',
    },
    fieldCheckboxControl: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      margin: "10px 0",
      position: "relative",
    },
    switchTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      alignItems: "center",
      display: "flex",
    },
    inputLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      marginBottom: "5px",
      alignItems: "center",
      display: "flex",
    },
    numberInputElemnt: {
      height: "56px",
      padding: "10px",
      border: "none",
      "-moz-appearance": "textfield",
      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      width: "34%",
      textAlign: "center" as const,
    },
    inputElemnt1: {
      height: "56px",
      padding: "10px 12px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      fontFamily: "'Inter', sans-serif",
    },
    inputElementError: {
      border: "1px solid #F87171",
    },
    errorIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 16,
    },
    numberFieldControlIcon: {
      width: "24px",
      height: "24px",
      color: '#1A7BA4',
    },
    numberFieldControlButton: {
      backgroundColor: "rgba(26, 123, 164, 0.1)",
      border: "none",
      width: "33%",
      '&:hover': {
        backgroundColor: "rgba(26, 123, 164, 0.2)",
      }
    },
    numberFieldControlButtonLeft: {
      borderRadius: '8px 0 0 8px',
    },
    numberFieldControlButtonRight: {
      borderRadius: '0 8px 8px 0',
    },
    cancelButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginTop: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "1px solid #1A7BA4",
      background: "#FFFFFF",
      color: "#1A7BA4",
      padding: "0px 20px",
    },
    submitButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginTop: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 20px",
    },
    meterToReadBlcok: {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
    },
    fieldControl1: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0",
      position: "relative",
    },
    confirmDelete:{
      padding: "6px 12px",
      borderRadius: "5px",
      textTransform: "none",
      backgroundColor:"white",
      color:"#DC2626",
      border:"1px solid #DC2626",
      fontFamily:"'Inter', sans-serif",
   },
   actionButton: {
    width: '20px',
    height: '20px',
    marginRight: '6px'
   },
   dialogTitleText: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily:"'Inter', sans-serif",
   },
   chevronIconActive: {
     "> path": {
       fill: "#475569",
     },
   },
   chevronIconDisabled: {
     "> path": {
       fill: "#64748B",
     },
   },
  };