export const searchIcon = require("../assets/search.svg");
export const editIcon = require("../assets/edit.svg");
export const noPropertieisIcon = require("../assets/noPropertieis.svg");
export const leftButton = require("../assets/leftButton.svg");
export const rightButton = require("../assets/rightButton.svg");
export const addIcon = require("../assets/add.svg");
export const deleteIcon = require("../assets/delete.svg");
export const errorIcon = require("../assets/errorIcon.svg");
export const removeIcon = require("../assets/remove.svg");
export const caledarIcon = require("../assets/calendar.svg");
export const branch1 = require("../assets/branch1.svg");
export const branch2 = require("../assets/branch2.svg");
export const coldIcon = require("../assets/coldIcon.svg");
export const hotIcon = require("../assets/hotIcon.svg");
export const leftArrowIcon = require("../assets/leftArrow.svg");
export const hotWaterIcon = require("../assets/hotWaterIcon.png");
export const coldWaterIcon = require("../assets/coldWaterIcon.png");
// export const passwordHiddenIcon = require("../assets/passwordHiddenIcon.svg");
// export const errorIcon = require("../assets/errorIcon.svg");
