export const banner = require("../assets/image_banner-01.png");
export const navLogo = require("../assets/image_logo.png");

export const passwordHiddenIcon = require("../assets/passwordHiddenIcon.svg");
export const errorIcon = require("../assets/errorIcon.svg");
export const closeIcon = require("../assets/closeIcon.svg");

export const addIcon = require("../assets/add.svg");
export const removeIcon = require("../assets/remove.svg");

export const customerIcon = require("../assets/customer.svg");
export const addressIcon = require("../assets/address.svg");
export const emailIcon = require("../assets/email.svg");
export const propertyIcon = require("../assets/property.svg");

export const leftArrowIcon = require("../assets/leftArrow.svg");
export const rightArrowIcon = require("../assets/rightArrow.svg");
