import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reports {
  id: string;
  reportId: string;
  propertysName: string;
  generatedAt: string;
  type: string;
  paymentStatus: PaymentStatus;
}

interface City {
  id: number;
  value: string;
}

interface State {
  id: number;
  value: string;
}

interface AlternativeEmail {
  email: string;
  useFor: string[];
}

interface S {
  step: number;
  validate: boolean;
  reportList: Reports[];
  cities: City[];
  states: State[];
  alternativeEmail: AlternativeEmail[];
  showPassword: boolean;
  showConfirmPassword: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class SettingsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      step: 1,
      validate: false,
      cities: [
        {
          id: 1,
          value: "City 1",
        },
        {
          id: 2,
          value: "City 2",
        },
        {
          id: 3,
          value: "City 3",
        },
        {
          id: 4,
          value: "City 4",
        },
        {
          id: 5,
          value: "City 5",
        },
      ],
      states: [
        {
          id: 1,
          value: "State 1",
        },
        {
          id: 2,
          value: "State 2",
        },
        {
          id: 3,
          value: "State 3",
        },
        {
          id: 4,
          value: "State 4",
        },
        {
          id: 5,
          value: "State 5",
        },
      ],
      alternativeEmail: [
        {
          email: "",
          useFor: [],
        },
      ],
      reportList: [
        {
          id: "1",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Water (hot)",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "2",
          reportId: "HD81G3",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Pending,
        },
        {
          id: "3",
          reportId: "D782HV",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Water (cold)",
          paymentStatus: PaymentStatus.NoInvoice,
        },
        {
          id: "4",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "5",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "6",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Water (cold)",
          paymentStatus: PaymentStatus.NoInvoice,
        },
        {
          id: "7",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Water (hot)",
          paymentStatus: PaymentStatus.Pending,
        },
      ],
      showPassword: false,
      showConfirmPassword: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  setStep = (step: number) => {
    this.setState({ step });
  };

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  setShowPassword = (show: boolean) => {
    this.setState({ showPassword: show });
  };

  setShowConfirmPassword = (show: boolean) => {
    this.setState({ showConfirmPassword: show });
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  componentDidMount = async () => {
    const userRole = await getStorageData('role');

    if (userRole !== 'user') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  }
  // Customizable Area End
}
