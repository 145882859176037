import React from "react";
// Customizable Area Start

import {
  Typography,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

// Customizable Area End

import AdminSettingsPageController, {
  Props,
  configJSON,
} from "./AdminSettingsPageController";
// Customizable Area Start

// Customizable Area End
export default class AdminSettingsPage extends AdminSettingsPageController {
  constructor(props: Props) {
    super(props);
  }

  serviceChargeSchema = () => {
    return Yup.object().shape({
      serviceCharge: Yup.string().required("Service Charge is required"),
    });
  };

  waterSettingSchema = () => {
    return Yup.object().shape({
      consumption1: Yup.string().required("Consumption #1 is required"),
      consumption2: Yup.string().required("Consumption #2  is required"),
      consumption3: Yup.string().required("Consumption #3  is required"),
      rate1: Yup.string().required("Rate #1  is required"),
      rate2: Yup.string().required("Rate #2  is required"),
      rate3: Yup.string().required("Rate #3  is required"),
    });
  };

  serviceChargeForm = () => {
    return (
      <Formik
        initialValues={{
          serviceCharge: "$12",
          isEdit: false,
        }}
        validateOnMount={true}
        validateOnChange={true}
        validationSchema={this.serviceChargeSchema}
        onSubmit={() => {
          this.setValidate(true);
        }}
        data-test-id="serviceChargeForm"
      >
        {({ setFieldValue, values, errors, handleSubmit }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={webStyle.columnFieldControl}>
                <label style={webStyle.inputLabel}>
                  {configJSON.serviceChargeText}
                </label>

                <input
                    style={{...webStyle.inputElemnt, ...(!values.isEdit ? webStyle.inputElemntDisabled : {})}}
                  data-test-id="serviceCharge"
                  type="text"
                  value={values?.serviceCharge}
                  onChange={(event) => {
                    setFieldValue("serviceCharge", event.target.value);
                  }}
                />
              </Box>
              <Box sx={webStyle.fieldControlFirstStepGroup}>
                {values.isEdit ? (
                  <>
                    <button
                      style={webStyle.cancelButton}
                      data-test-id="cancelBtn"
                      type="button"
                      onClick={() => {
                        setFieldValue('isEdit', false);
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.cancelButtonText}</Typography>
                    </button>
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="button"
                      onClick={() => {
                        this.setValidate(true);
                        if (Object.keys(errors).length === 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.submitButtonText}</Typography>
                    </button>
                  </>
                ) : (
                <button
                  style={webStyle.submitButton}
                  data-test-id="editBtn"
                  type="button"
                  onClick={() => {
                    setFieldValue('isEdit', true);
                  }}
                >
                  <Typography sx={webStyle.buttonText}>{configJSON.editButtonText}</Typography>
                </button>
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    );
  };

  waterSettingForm = () => {
    return (
      <Formik
        initialValues={{
          consumption1: "10.000",
          consumption2: "200.000",
          consumption3: "1.000.000",
          rate1: "$11.63",
          rate2: "$14.63",
          rate3: "$20.94",
          isEdit: false,
        }}
        validateOnMount={true}
        validateOnChange={true}
        validationSchema={this.waterSettingSchema}
        onSubmit={() => {
          this.setValidate(true);
        }}
        data-test-id="waterSettingForm"
      >
        {({ setFieldValue, values, errors, handleSubmit }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel}>
                    {configJSON.consumptionTitleText}
                  </label>

                  <input
                    style={{...webStyle.inputElemnt, ...(!values.isEdit ? webStyle.inputElemntDisabled : {})}}
                    data-test-id="consumption1"
                    type="text"
                    value={values?.consumption1}
                    onChange={(event) => {
                      setFieldValue("consumption1", event.target.value);
                    }}
                    disabled={!values.isEdit}
                  />
                </Box>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel}>
                    {configJSON.rateTitleText}
                  </label>

                  <input
                    style={{...webStyle.inputElemnt, ...(!values.isEdit ? webStyle.inputElemntDisabled : {})}}
                    data-test-id="rate1"
                    type="text"
                    value={values?.rate1}
                    onChange={(event) => {
                      setFieldValue("rate1", event.target.value);
                    }}
                    disabled={!values.isEdit}
                  />
                </Box>
              </Box>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.columnFieldControl}>
                  <input
                    style={{...webStyle.inputElemnt, ...(!values.isEdit ? webStyle.inputElemntDisabled : {})}}
                    data-test-id="consumption2"
                    type="text"
                    value={values?.consumption2}
                    onChange={(event) => {
                      setFieldValue("consumption2", event.target.value);
                    }}
                    disabled={!values.isEdit}
                  />
                </Box>
                <Box sx={webStyle.columnFieldControl}>
                  <input
                    style={{...webStyle.inputElemnt, ...(!values.isEdit ? webStyle.inputElemntDisabled : {})}}
                    data-test-id="rate2"
                    type="text"
                    value={values?.rate2}
                    onChange={(event) => {
                      setFieldValue("rate2", event.target.value);
                    }}
                    disabled={!values.isEdit}
                  />
                </Box>
              </Box>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.columnFieldControl}>
                  <input
                    style={{...webStyle.inputElemnt, ...(!values.isEdit ? webStyle.inputElemntDisabled : {})}}
                    data-test-id="consumption3"
                    type="text"
                    value={values?.consumption3}
                    onChange={(event) => {
                      setFieldValue("consumption3", event.target.value);
                    }}
                    disabled={!values.isEdit}
                  />
                </Box>
                <Box sx={webStyle.columnFieldControl}>
                  <input
                    style={{...webStyle.inputElemnt, ...(!values.isEdit ? webStyle.inputElemntDisabled : {})}}
                    data-test-id="rate3"
                    type="text"
                    value={values?.rate3}
                    onChange={(event) => {
                      setFieldValue("rate3", event.target.value);
                    }}
                    disabled={!values.isEdit}
                  />
                </Box>
              </Box>
              <Box sx={webStyle.fieldControlFirstStepGroup}>
                {values.isEdit ? (
                  <>
                    <button
                      style={webStyle.cancelButton}
                      data-test-id="cancelBtn"
                      type="button"
                      onClick={() => {
                        setFieldValue('isEdit', false);
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.cancelButtonText}</Typography>
                    </button>
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="button"
                      onClick={() => {
                        this.setValidate(true);
                        if (Object.keys(errors).length === 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.submitButtonText}</Typography>
                    </button>
                  </>
                ) : (
                  <button
                    style={webStyle.submitButton}
                    data-test-id="editBtn"
                    type="button"
                    onClick={() => {
                      setFieldValue('isEdit', true);
                    }}
                  >
                    <Typography sx={webStyle.buttonText}>{configJSON.editButtonText}</Typography>
                  </button>
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    );
  };

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addReadingContainer}>
        <Box sx={webStyle.addReadingBlock}>
          <Box sx={webStyle.settingFormContainer}>
            <Box sx={webStyle.settingTitle}>
              <Typography style={webStyle.formTitle}>{configJSON.settingTitleText}</Typography>
            </Box>
            <Tabs value={this.state.step} onChange={(_, newValue) => this.setStep(newValue)} sx={webStyle.tabsContainer}>
              <Tab
                data-test-id="firstStep"
                value={1}
                label="Water settings"
                sx={webStyle.tabButton(this.state.step === 1)}
              />
              <Tab
                value={2}
                label="Electricity settings"
                data-test-id="secondStep"
                sx={webStyle.tabButton(this.state.step === 2)}
              />
              <Tab
                value={3}
                label="Service charge"
                data-test-id="thirdStep"
                sx={webStyle.tabButton(this.state.step === 3)}
              />
            </Tabs>
            {this.state.step === 1 && this.waterSettingForm()}
            {this.state.step === 3 && this.serviceChargeForm()}
          </Box>
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  addReadingContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    height: '100%',
    fontFamily: "'Inter', sans-serif",
  },
  addReadingBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  settingFormContainer: {
    margin: "40px auto",
    maxWidth: "600px",
    width: "80%",
  },
  settingTitle: {
    margin: "0 0 20px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    marginBottom: "15px",
  },
  fieldGroupContral: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    gap: '16px',
  },
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "14px 0 0",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "14px 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  tabsContainer: {
    borderBottom: '1px solid #E2E8F0',
    margin: '0 0 30px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
    '& .Mui-selected': {
      color: '#1A7BA4 !important',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1A7BA4 !important',
    }
  },
  activeTabItemContainer: {
    cursor: "pointer",
    borderBottom: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row",
    color: '#1A7BA4'
  },
  tabItemContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
  },
  tabButton: (isActive: boolean) => ({
    fontWeight: isActive ? 700 : 400,
    color: isActive ? '#1A7BA4' : '#64748B',
    textTransform: 'none',
    padding: '8px 12px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
  }),
  tabTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    margin: "7px",
  },
  activeTabTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#64748B",
    margin: "7px",
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
  },
  inputElemntDisabled: {
    backgroundColor: '#F1F5F9',
  },
  columnFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0",
    position: "relative",
  },
};
// Customizable Area End
