import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { noPropertieisIcon, downloadIcon } from "./assets";
// Customizable Area End

import AnaliticsPageController, {
  Props,
  configJSON,
} from "./AnaliticsPageController";
import { CustomChart } from "../../../components/src/CustomChart.web";
// Customizable Area Start

// Customizable Area End
export default class AnaliticsPage extends AnaliticsPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      // Customizable Area Start
      <Box sx={webStyle.reportsContainer}>
        {this.state.reportList.length === 0 ? (
          <Box sx={webStyle.noReportBlock}>
            <Box sx={webStyle.noReportBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography style={webStyle.noReportTitle}>
                {configJSON.noDataTitleText}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={webStyle.reportsTableHeader}>
              <Typography sx={webStyle.reportTitle}>
                {configJSON.analiticsText}
              </Typography>
              <Box sx={webStyle.dashboardControl}>
                <Box sx={webStyle.searchContainer}>
                  <label style={webStyle.inputLabel} htmlFor={"customer"}>
                    {configJSON.customerText}
                  </label>
                  <Box sx={webStyle.fieldControl}>
                    <Select
                      value={this.state.customerId}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      data-test-id="customer"
                      onChange={(event) => {
                        this.changeCustomerId(
                          event.target.value as number
                        );
                      }}
                      sx={webStyle.select}
                    >
                      {this.state.customerList.map(
                        (customer: string, index: number) => (
                          <MenuItem
                            value={index}
                            sx={webStyle.selectOption}
                            key={index}
                          >
                            {customer}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </Box>
                </Box>
                <Box sx={webStyle.searchContainer}>
                  <label style={webStyle.inputLabel} htmlFor={"meterNumber"}>
                    {configJSON.yearText}
                  </label>
                  <Box sx={webStyle.fieldControl}>
                    <Select
                      value={this.state.dashboardFilter}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      data-test-id="premise"
                      onChange={(event) => {
                        this.changeDashboardFilter(
                          event.target.value as number
                        );
                      }}
                      sx={webStyle.select}
                    >
                      <MenuItem value={0} sx={webStyle.selectOption}>
                        {configJSON.lastYearText}
                      </MenuItem>
                      <MenuItem value={1} sx={webStyle.selectOption}>
                        {configJSON.previusYearText}
                      </MenuItem>
                      <MenuItem value={2} sx={webStyle.selectOption}>
                        {configJSON.currentYearText}
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
                <button style={webStyle.downloadButton} onClick={() => {}}>
                  <img src={downloadIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.downloadButtonText}>
                    {configJSON.saveAsPdfText}
                  </Typography>
                </button>
              </Box>
            </Box>
            <Box sx={webStyle.dashboardBody}>
              <Typography style={webStyle.cunsumptionTitle}>
                {configJSON.cunsumptionText}
              </Typography>
              <Box sx={webStyle.filterBar}>
                <Box sx={webStyle.fieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"meterNumber"}>
                    {configJSON.propertyNameText}
                  </label>
                  <Select
                    value={this.state.propertyName}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="premise"
                    style={
                      this.state.propertyName.length
                        ? webStyle.selectOtherActive
                        : undefined
                    }
                    sx={{ ...webStyle.select, ...webStyle.selectProperty }}
                    renderValue={(select) => select || "Select"}
                    placeholder={"Select"}
                    onChange={(event) => {
                      this.changePropertyName(event.target.value);
                    }}
                  >
                    <MenuItem
                      value={"Property Name 1"}
                      sx={webStyle.selectOption}
                    >
                      Property Name 1
                    </MenuItem>
                    <MenuItem
                      value={"Property Name 2"}
                      sx={webStyle.selectOption}
                    >
                      Property Name 2
                    </MenuItem>
                    <MenuItem
                      value={"Property Name 3"}
                      sx={webStyle.selectOption}
                    >
                      Property Name 3
                    </MenuItem>
                  </Select>
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"meterNumber"}>
                    {configJSON.tenantNameText}
                  </label>
                  <Select
                    value={this.state.tenant}
                    multiple
                    renderValue={(selected) =>
                      selected.length ? `${selected.length} selected` : "Select"
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="premise"
                    sx={{
                      ...webStyle.selectOther,
                      ...(this.state.tenant.length
                        ? webStyle.selectOtherActive
                        : {}),
                    }}
                    placeholder={"Select"}
                    onChange={(event) => {
                      console.log("event.target.value", event.target.value);
                      this.changeTenant(
                        typeof event.target.value === "string"
                          ? event.target.value.split(",")
                          : event.target.value
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {this.state.tennatList.filter((tenant) =>
                      this.state.tenant.includes(tenant)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            this.state.tennatList.filter((tenant) =>
                              this.state.tenant.includes(tenant)
                            ).length
                          } selected`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {this.state.tennatList
                      .filter((tenant) => this.state.tenant.includes(tenant))
                      .map((tenant) => (
                        <MenuItem
                          value={tenant}
                          sx={webStyle.selectOtherOption}
                        >
                          <Checkbox
                            checked={this.state.tenant.includes(tenant)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText
                            primary={tenant}
                            sx={webStyle.checkboxLabel}
                          />
                        </MenuItem>
                      ))}

                    {this.state.tennatList.filter(
                      (tenant) => !this.state.tenant.includes(tenant)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            this.state.tennatList.filter(
                              (tenant) => !this.state.tenant.includes(tenant)
                            ).length
                          } remaining`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {this.state.tennatList
                      .filter((tenant) => !this.state.tenant.includes(tenant))
                      .map((tenant) => (
                        <MenuItem
                          value={tenant}
                          sx={webStyle.selectOtherOption}
                        >
                          <Checkbox
                            checked={this.state.tenant.includes(tenant)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText
                            primary={tenant}
                            sx={webStyle.checkboxLabel}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"meterNumber"}>
                    {configJSON.meterTypeText}
                  </label>
                  <Select
                    value={this.state.meterType}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="premise"
                    sx={{
                      ...webStyle.selectOther,
                      ...(this.state.meterType
                        ? webStyle.selectOtherActive
                        : {}),
                    }}
                    placeholder={"Select"}
                    renderValue={(selected) => selected || "Select"}
                    onChange={(event) => {
                      this.changeMeterType(event.target.value);
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"Water"} sx={webStyle.selectOtherOption}>
                      <Box sx={webStyle.radioWrapper}>
                        <input
                          style={webStyle.radioButtonElemnt}
                          data-test-id={`meters.type.water`}
                          name="meterType"
                          value={"Water"}
                          type="radio"
                          checked={this.state.meterType === "Water"}
                        />
                        <label
                          style={webStyle.radioButtonLabel}
                          htmlFor="Water"
                        >
                          {configJSON.waterTitleText}
                        </label>
                      </Box>
                    </MenuItem>
                    <MenuItem
                      value={"Electric"}
                      sx={webStyle.selectOtherOption}
                    >
                      <Box sx={webStyle.radioWrapper}>
                        <input
                          style={webStyle.radioButtonElemnt}
                          data-test-id={`meters.type.electric`}
                          name="meterType"
                          value={"Electric"}
                          type="radio"
                          checked={this.state.meterType === "Electric"}
                        />
                        <label
                          style={webStyle.radioButtonLabel}
                          htmlFor="Electrical"
                        >
                          {configJSON.electricTypeText}
                        </label>
                      </Box>
                    </MenuItem>
                  </Select>
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"meterNumber"}>
                    {configJSON.meterNumberText}
                  </label>
                  <Select
                    value={this.state.meter}
                    displayEmpty
                    multiple
                    renderValue={(selected) =>
                      selected.length ? `${selected.length} selected` : "Select"
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="premise"
                    sx={{
                      ...webStyle.selectOther,
                      ...(this.state.meter.length
                        ? webStyle.selectOtherActive
                        : {}),
                    }}
                    placeholder={"Select"}
                    onChange={(event) => {
                      this.changeMeter(
                        typeof event.target.value === "string"
                          ? event.target.value.split(",")
                          : event.target.value
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {this.state.meterList.filter((meter) =>
                      this.state.meter.includes(meter)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            this.state.meterList.filter((meter) =>
                              this.state.meter.includes(meter)
                            ).length
                          } selected`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {this.state.meterList
                      .filter((meter) => this.state.meter.includes(meter))
                      .map((meter) => (
                        <MenuItem value={meter} sx={webStyle.selectOtherOption}>
                          <Checkbox
                            checked={this.state.meter.includes(meter)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText
                            primary={meter}
                            sx={webStyle.checkboxLabel}
                          />
                        </MenuItem>
                      ))}
                    {this.state.meterList.filter(
                      (meter) => !this.state.meter.includes(meter)
                    ).length > 0 ? (
                      <Box sx={webStyle.dividerContainer}>
                        <Box sx={webStyle.dividerText}>
                          {`${
                            this.state.meterList.filter(
                              (meter) => !this.state.meter.includes(meter)
                            ).length
                          } remaining`}
                        </Box>
                        <Box sx={webStyle.dividerSpace} />
                      </Box>
                    ) : (
                      ""
                    )}
                    {this.state.meterList
                      .filter((meter) => !this.state.meter.includes(meter))
                      .map((meter) => (
                        <MenuItem value={meter} sx={webStyle.selectOtherOption}>
                          <Checkbox
                            checked={this.state.meter.includes(meter)}
                            sx={webStyle.checkbox}
                          />
                          <ListItemText
                            primary={meter}
                            sx={webStyle.checkboxLabel}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </Box>
              <Box sx={webStyle.chartContainer}>
                <Box sx={webStyle.chartSelectorContainer}>
                  <button
                    style={
                      this.state.chartType === "line"
                        ? webStyle.chartSelectorActive
                        : webStyle.chartSelector
                    }
                    onClick={() => {
                      this.changeChartType("line");
                    }}
                  >
                    <Typography
                      style={
                        this.state.chartType === "line"
                          ? webStyle.chartSelectorActiveTypographyText
                          : webStyle.chartSelectorTypographyText
                      }
                    >
                      {configJSON.lineChartText}
                    </Typography>
                  </button>
                  <button
                    style={
                      this.state.chartType === "bar"
                        ? webStyle.chartSelectorActive
                        : webStyle.chartSelector
                    }
                    onClick={() => {
                      this.changeChartType("bar");
                    }}
                  >
                    <Typography
                      style={
                        this.state.chartType === "bar"
                          ? webStyle.chartSelectorActiveTypographyText
                          : webStyle.chartSelectorTypographyText
                      }
                    >
                      {configJSON.barChartText}
                    </Typography>
                  </button>
                </Box>
                <CustomChart
                  chartType={this.state.chartType}
                  chartData={[0, 1, 2, 3, 4, 5, 6]}
                  key={1}
                />
              </Box>
              <Box sx={webStyle.reportsTable}>
                <Box sx={webStyle.reportsTableHead}>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.tenantText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.meterNumberText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.cunsumptionText}
                  </Typography>
                  <Typography style={webStyle.headerTitle}>
                    {configJSON.dateText}
                  </Typography>
                </Box>
                {this.state.reportList.map((report) => (
                  <Box sx={webStyle.reportsTableBody} key={report.id}>
                    <Box sx={webStyle.reportItem}>{report.tenant}</Box>
                    <Box sx={webStyle.reportItem}>{report.meter}</Box>
                    <Box sx={webStyle.reportItem}>{report.consumption}</Box>
                    <Box sx={webStyle.reportItem}>{report.date}</Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  reportsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(248, 250, 252)",
    padding: "0px 30px",
    minHeight: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  reportsTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
  },
  dashboardControl: {
    display: "flex",
    flexDirection: "row",
  },
  reportsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    margin: "0px 10px",
  },
  reportsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  reportsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    margin: "5px 0px",
    alignItems: "center",
    display: "flex",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "25%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  downloadButton: {
    backgroundColor: "#F8FAFC",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "10px 16px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px",
  },
  downloadButtonText: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    marginLeft: "8px",
  },
  reportItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "25%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  reportItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "200px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noReportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  paidStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  pendingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  noInvoiceStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#F1F5F9",
    color: "#475569",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  reportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  reportCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fonWeight: 500,
    lineHeight: "22px",
    color: "#334155",
  },
  select: {
    fontFamily: "'Inter', sans-serif",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    backgroundColor: '#FFFFFF',
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  selectProperty: {
    color: "#94A3B8",
  },
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E8FBFF",
      color: "#1A7BA4",
      fontWeight: "600",
    },
  },
  selectOther: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    height: "44px",
    borderRadius: "8px",
    backgroundColor: '#FFFFFF',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  selectOtherActive: {
    color: "#0F172A",
  },
  selectOtherOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px !important",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    gap: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "inherit",
      color: "#0F172A",
      fontWeight: "400",
    },
  },
  selectOptionStart: {},
  dashboardBody: {
    border: "1.3px solid #CBD5E1",
    borderRadius: "19px",
    padding: "20px 10px",
    margin: "10px 0px",
    backgroundColor: "#FFFFFF",
  },
  cunsumptionTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "19px",
    fontWeight: 700,
    lineHeight: "29px",
    color: "#64748B",
    margin: "0px 10px",
  },
  filterBar: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
  },
  radioWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  radioButtonElemnt: {
    height: "20px",
    width: "20px",
    padding: 0,
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    margin: 0,
  },
  radioButtonLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  chartSelectorContainer: {
    backgroundColor: "#E2E8F0",
    height: "40px",
    width: "fit-content",
    display: "flex",
    gap: "7px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 4px",
    marginBottom: "20px",
    borderRadius: "10px",
    border: "1px solid #E2E8F0",
  },
  chartContainer: {
    padding: " 0px 10px 20px 10px",
  },
  chartSelector: {
    color: "#475569",
    border: "none",
    backgroundColor: "#E2E8F0",
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    padding: "5px 10px",
    height: "32px",
    borderRadius: "7px",
    cursor: "pointer",
  },
  chartSelectorActive: {
    color: "#0F172A",
    fontWeight: "500",
    border: "none",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    borderRadius: "7px",
    padding: "5px 10px",
    height: "32px",
    cursor: "pointer",
  },
  chartSelectorTypographyText: {
    color: "#475569",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  chartSelectorActiveTypographyText: {
    color: "#0F172A",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
  },
  dividerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "28px",
    alignItems: "center",
    marginBottom: "12px",
  },
  dividerText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    margin: "0px 12px 0px 8px",
  },
  dividerSpace: {
    borderBottom: "1px solid #CBD5E1",
    display: "flex",
    flex: "auto",
    height: "0px",
  },
  checkbox: {
    padding: 0,
  },
  checkboxLabel: {
    "> span": {
      fontSize: "14px",
      lineHeight: "22px",
      fontFamily: "'Inter', sans-serif",
    },
  },
};
// Customizable Area End
