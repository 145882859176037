import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reports {
  id: string;
  reportId: string;
  propertysName: string;
  generatedAt: string;
  type: string;
  paymentStatus: PaymentStatus;
}

interface S {
  reportList: Reports[];
  // Customizable Area Start
  deleteDialouge:boolean;
  addFRdialog:boolean;
  showPassword:boolean,
  showConfirmPassword:boolean,
  passwordValidations:{ 
    hasCapital: boolean,
    hasLowercase: boolean,
    hasNumber: boolean,
    minLength: boolean
  },
  firstName:string,
  lastName:string,
  email:string,
  password:string,
  confirmPassword:string,
  firstNameError:string,
  lastNameError:string,
  emailError:string,
  passwordError:string,
  confirmPasswordError:string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class fieldRepresentativesPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      reportList: [
        {
          id: "1",
          reportId: "Michael",
          propertysName: "Smith",
          generatedAt: "4/12/2023",
          type: "Water (hot)",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "2",
          reportId: "John",
          propertysName: "Johnson",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Pending,
        },
        {
          id: "3",
          reportId: "Emily",
          propertysName: "Brown",
          generatedAt: "4/12/2023",
          type: "Water (cold)",
          paymentStatus: PaymentStatus.Pending,
        },
        {
          id: "4",
          reportId: "Sarah",
          propertysName: "Davis",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "5",
          reportId: "David",
          propertysName: "Miller",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "6",
          reportId: "Jessica",
          propertysName: "Wilson",
          generatedAt: "4/12/2023",
          type: "Water (cold)",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "7",
          reportId: "James",
          propertysName: "Moore",
          generatedAt: "4/12/2023",
          type: "Water (hot)",
          paymentStatus: PaymentStatus.Pending,
        },
      ],
     deleteDialouge:false,
     addFRdialog:false,
     showPassword:false,
     showConfirmPassword:false,
    passwordValidations:{ 
      hasCapital: false,
      hasLowercase: false,
      hasNumber: false,
      minLength: false},
      firstName:"",
      lastName:"",
      email:"",
      password:"",
      confirmPassword:"",
      firstNameError:"",
      lastNameError:"",
      emailError:"",
      passwordError:"",
      confirmPasswordError:"",
    },
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }


  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  resendInvite = (reportId: string) => {
  };

  editReport = (reportId: string) => {
  };

  deleteReport = () => {
  };

  resetAddFRForm = () => {
    this.setState({
      showPassword: false,
      showConfirmPassword: false,
      passwordValidations: {
        hasCapital: false,
        hasLowercase: false,
        hasNumber: false,
        minLength: false
      },
      firstName:"",
      lastName:"",
      email:"",
      password:"",
      confirmPassword:"",
      firstNameError:"",
      lastNameError:"",
      emailError:"",
      passwordError:"",
      confirmPasswordError:"",
    })
  }

  handleClose = () => {
    this.setState({deleteDialouge:false})
  };
  handleCloseAddFR = () => {
    this.setState({addFRdialog:false})
    this.resetAddFRForm();
  };

  searchReports = (e: string)=>{
    
  }

  toggleShowPassword = () => {
    this.setState({showPassword:!this.state.showPassword})
  };
  toggleShowConfirmPassword = () => {
    this.setState({showConfirmPassword:!this.state.showConfirmPassword})
  };

  setFirstName=(event: { target: { value: any; }; })=>{
    const firstName = event.target.value
    this.setState({firstName,firstNameError:""})
      
  }

  setLastName=(event: { target: { value: any; }; })=>{
    const lastName = event.target.value
    this.setState({lastName,lastNameError:""})

  }

  setEmail=(event: { target: { value: any; }; })=>{
    const email = event.target.value
    this.setState({email,emailError:""})
      
  }

  setConfirmPassword=(event: { target: { value: any; }; })=>{
    const confirmPassword = event.target.value
    this.setState({confirmPassword,confirmPasswordError:""})
     
  }
  handlePasswordChange = (e: { target: { value: any; }; }) => {
    const newPassword = e.target.value;
    this.setState({password:newPassword,passwordError:""})
    this.validatePassword(newPassword);
  };
  
  validatePassword = (password: string) => {
    const validations = {
      hasCapital: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      minLength: password.length >= 8,
    };
    this.setState({ passwordValidations: validations });
  };
 
  handleSave=()=>{
    if(this.state.firstName === ""){
      this.setState({firstNameError:"First name field can’t be empty"})
    }
    if(this.state.lastName === ""){
      this.setState({lastNameError:"Last name field can’t be empty"})
    }
    if(this.state.email === ""){
      this.setState({emailError:"Email field can’t be empty"})
    }
    if(this.state.password === ""){
      this.setState({passwordError:"Password field can’t be empty"})
    }
    if(this.state.confirmPassword === ""){
      this.setState({confirmPasswordError:"Confirm password field can’t be empty"})
    }
    if(this.state.confirmPassword !== this.state.password){
      this.setState({confirmPasswordError:"Passwords do not match"})
    }

    const isEmailValid = this.validateEmail(this.state.email);
    if (!isEmailValid) {
      this.setState({emailError:"Email is not valid"})
    }
  }
  
  validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (!email || !emailRegex.test(email)) {
      return false; 
    }
    
    this.setState({ emailError: '' }); 
    return true; 
  };

  componentDidMount = async () => {
    const userRole = await getStorageData('role');

    if (userRole !== 'admin') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  }
  
  // Customizable Area End
}
