import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Modal
} from "@mui/material";
import { searchIcon, showIcon, deleteIcon, closeIcon, downArrowIcon } from "./assets";
// Customizable Area End

import AdminSupportPageController, {
  // CustomeerAppStatus,
  CustomerAppStatus,
  Props,
  configJSON,
} from "./AdminSupportPageController";
// Customizable Area Start

// Customizable Area End
export default class AdminSupportPage extends AdminSupportPageController {
  constructor(props: Props) {
    super(props);
  }

  customerAppList = () => {
    return (
      <>
        <Box sx={webStyle.customerAppTitle}>
          <Box sx={webStyle.tabContainer}>
            <Box sx={webStyle.searchContainer}>
              <input
                style={webStyle.inputElemnt}
                data-test-id="search"
                type={"text"}
                placeholder={configJSON.searchPlaceholderText}
                onChange={(event) => {
                  this.searchCustomerApp(event.target.value);
                }}
              />
              <img src={searchIcon} alt="" style={webStyle.searchIcon} />
            </Box>
            <Box sx={webStyle.selectContainer}>
              <label style={webStyle.inputLabel} htmlFor={"date"}>
                {configJSON.dateText}
              </label>
              <Box sx={webStyle.fieldControl}>
                <Select
                  value={this.state.customerAppFilter}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  data-test-id="date"
                  onChange={(event) => {
                    this.changeCustomerAppFilter(
                      event.target.value as number);
                  }}
                  sx={webStyle.select}
                >
                  <MenuItem value={0} sx={webStyle.selectOption}>
                    {configJSON.lastYearText}
                  </MenuItem>
                  <MenuItem value={1} sx={webStyle.selectOption}>
                    {configJSON.previusYearText}
                  </MenuItem>
                  <MenuItem value={2} sx={webStyle.selectOption}>
                    {configJSON.currentYearText}
                  </MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
          <Typography sx={webStyle.customerAppCount}>
            {this.state.customerAppList.length + " results"}
          </Typography>
        </Box>
        <Box sx={webStyle.customerAppsTable}>
          <Box sx={webStyle.customerAppsTableHead}>
            <Typography style={webStyle.headerCompanyNameTitle}>
              {configJSON.companyNameText}
            </Typography>
            <Typography style={webStyle.headerServicesNeededTitle}>
              {configJSON.serviceNeededText}
            </Typography>
            <Typography style={webStyle.headerCommentTitle}>
              {configJSON.commentText}
            </Typography>
            <Typography style={webStyle.headerCreatedAtTitle}>
              {configJSON.createdAtText}
            </Typography>
            <Typography style={webStyle.headerStatusTitle}>
              {configJSON.statusText}
            </Typography>
            <Typography style={webStyle.headerTitleAction}>
              {configJSON.actionsText}
            </Typography>
          </Box>
          {this.state.customerAppList.map((app) => (
            <Box sx={webStyle.customerAppsTableBody} key={app.id}>
              <Box sx={webStyle.customerAppCompanyNameItem}>{app.companyName}</Box>
              <Box sx={webStyle.customerAppServicesNeededItem}>{app.serviceNeeded}</Box>
              <Box sx={webStyle.customerAppCommentItem}>{app.comment}</Box>
              <Box sx={webStyle.customerAppCreatedAtItem}>{app.createdAt}</Box>
              <Box sx={webStyle.customerAppStatusItem}>
                {CustomerAppStatus.Processing === app.status ? (
                  <>
                    <Typography sx={webStyle.processingStyle}>
                      {" "}{app.status}{" "}
                    </Typography>
                    <Typography sx={webStyle.downArrowBlock}>
                      <img src={downArrowIcon} alt="" style={webStyle.btnIcon}/>
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                {CustomerAppStatus.Processed === app.status ? (
                  <>
                    <Typography sx={webStyle.processedStyle}>
                      {" "}{app.status}{" "}
                    </Typography>
                    <Typography sx={webStyle.downArrowBlock}>
                      <img src={downArrowIcon} alt="" style={webStyle.btnIcon}/>
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Box>
              <Box sx={webStyle.customerAppItemAction}>
                <button
                  style={webStyle.showButton}
                  data-test-id={`showCustomerApp.${app.id}`}
                  onClick={() => {
                    this.openCustomerAppModal(app.id);
                  }}
                >
                  <img src={showIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.showText}
                  </Typography>
                </button>
                <button
                  style={webStyle.deleteButton}
                  data-test-id={`deletePropeerties.${app.id}`}
                  onClick={() => {
                    this.deleteCustomeerApp(app.id);
                  }}
                >
                  <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.deleteText}
                  </Typography>
                </button>
              </Box>
            </Box>
          ))}
        </Box>
      </>
    )
  }

  contactFormList = () => {
    return (
      <>
        <Box sx={webStyle.contactFormTitle}>
          <Typography sx={webStyle.contactFormCount}>
            {this.state.contactFormList.length + " results"}
          </Typography>
        </Box>
        <Box sx={webStyle.contactFormsTable}>
          <Box sx={webStyle.contactFormsTableHead}>
            <Typography style={webStyle.headerContactFormNameTitle}>
              {configJSON.contactFormNameText}
            </Typography>
            <Typography style={webStyle.headerContactFormEmailTitle}>
              {configJSON.contactFormEmailText}
            </Typography>
            <Typography style={webStyle.headerContactFormPhoneTitle}>
              {configJSON.contactFormPhoneText}
            </Typography>
            <Typography style={webStyle.headerContactFormServicesNeededTitle}>
              {configJSON.contactFormServiceNeededText}
            </Typography>
            <Typography style={webStyle.headerContactFormCommentTitle}>
              {configJSON.contactFormCommentText}
            </Typography>
            <Typography style={webStyle.headerContactFormCreatedAtTitle}>
              {configJSON.contactFormCreatedAtText}
            </Typography>
            <Typography style={webStyle.headerContactFormTitleAction}>
              {configJSON.contactFormActionsText}
            </Typography>
          </Box>
          {this.state.contactFormList.map((form) => (
            <Box sx={webStyle.contactFormsTableBody} key={form.id}>
              <Box sx={webStyle.contactFormNameItem}>{form.name}</Box>
              <Box sx={webStyle.contactFormEmailItem}>{form.email}</Box>
              <Box sx={webStyle.contactFormPhoneItem}>{form.phone}</Box>
              <Box sx={webStyle.contactFormServicesNeededItem}>{form.serviceNeeded}</Box>
              <Box sx={webStyle.contactFormCommentItem}>{form.comment}</Box>
              <Box sx={webStyle.contactFormCreatedAtItem}>{form.createdAt}</Box>
              <Box sx={webStyle.contactFormItemAction}>
                <button
                  style={webStyle.showButton}
                  data-test-id={`showDetails.${form.id}`}
                  onClick={() => {
                    this.openContactFormModal(form.id);
                  }}
                >
                  <img src={showIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.viewDetailsText}
                  </Typography>
                </button>
              </Box>
            </Box>
          ))}
        </Box>
      </>
    )
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.supportContainer}>
        <Modal
          open={!!this.state.showContactFormModal || !!this.state.showCustomerAppModal}
          onClose={() => {
            this.closeModals();
          }}
          data-test-id={"modalWindow"}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={webStyle.modalBox}>
            <Box sx={webStyle.modalTitleBlock}>
              <Typography style={webStyle.modalTitle}>
                {!!this.state.showCustomerAppModal 
                  ? this.state.customerAppList[this.state.showCustomerAppModal]?.companyName
                  : "Contact"
                }
              </Typography>
              <button
                style={webStyle.modalCloseButton}
                onClick={() => this.closeModals()}
              >
                <img src={closeIcon} alt="Logo" style={{ height: "24px" }} />
              </button>
            </Box>
            <Box
              component="div"
              sx={webStyle.modalScrollBox}
            >
              {!!this.state.showContactFormModal && (
                <>
                  <Box sx={webStyle.modalBlock}>
                    <Typography sx={webStyle.modalBlockLabel}>
                      {configJSON.contactFormNameText}
                    </Typography>
                    <Typography sx={webStyle.modalBlockValuee}>
                      {this.state.contactFormList[this.state.showContactFormModal]?.name}
                    </Typography>
                  </Box>
                  <Box sx={webStyle.modalDoubleBlock}>
                    <Box sx={webStyle.modalBlock}>
                      <Typography sx={webStyle.modalBlockLabel}>
                        {configJSON.contactFormEmailText}
                      </Typography>
                      <Typography sx={webStyle.modalBlockValuee}>
                        {this.state.contactFormList[this.state.showContactFormModal]?.email}
                      </Typography>
                    </Box>
                    <Box sx={webStyle.modalBlock}>
                      <Typography sx={webStyle.modalBlockLabel}>
                        {configJSON.contactFormPhoneText}
                      </Typography>
                      <Typography sx={webStyle.modalBlockValuee}>
                        {this.state.contactFormList[this.state.showContactFormModal]?.phone}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
              <Box sx={webStyle.modalBlock}>
                <Typography sx={webStyle.modalBlockLabel}>
                  {configJSON.contactFormServiceNeededText}
                </Typography>
                <Typography sx={webStyle.modalBlockValuee}>
                  {!!this.state.showContactFormModal 
                    && this.state.contactFormList[this.state.showContactFormModal]?.serviceNeeded
                  }
                  {!!this.state.showCustomerAppModal 
                    && this.state.customerAppList[this.state.showCustomerAppModal]?.serviceNeeded
                  }
                </Typography>
              </Box>
              <Box sx={webStyle.modalBlock}>
                <Typography sx={webStyle.modalBlockLabel}>
                  {configJSON.contactFormCommentText}
                </Typography>
                <Typography sx={webStyle.modalBlockValuee}>
                  {!!this.state.showContactFormModal 
                    && this.state.contactFormList[this.state.showContactFormModal]?.comment
                  }
                  {!!this.state.showCustomerAppModal 
                    && this.state.customerAppList[this.state.showCustomerAppModal]?.comment
                  }
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Box sx={webStyle.supportTableHeader}>
          <Typography sx={webStyle.supportTitle}>
            {configJSON.supportText}
          </Typography>
        </Box>
        <Tabs value={this.state.step} onChange={(_, newValue) => this.setStep(newValue)} sx={webStyle.tabsContainer}>
          <Tab
            value={1}
            label="Customer app"
            data-test-id="firstStep"
            sx={webStyle.tabButton(this.state.step === 1)}
          />
          <Tab
            value={2}
            label="Contact form"
            data-test-id="secondStep"
            sx={webStyle.tabButton(this.state.step === 2)}
          />
        </Tabs>

        {this.state.step === 1 && this.customerAppList()}
        {this.state.step === 2 && this.contactFormList()}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  supportContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(248, 250, 252)",
    padding: "0px 30px",
    minHeight: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  supportTableHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  customerAppTitle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    width: "340px",
    position: "relative",
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
  },
  customerAppsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  customerAppsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  customerAppsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
  },
  fieldControl: {
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    position: "relative",
    width: "240px",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    margin: "5px 0px",
    alignItems: "center",
    display: "flex",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  headerCompanyNameTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(17% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerServicesNeededTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(19% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerCommentTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(32% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerCreatedAtTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(15% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerStatusTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(17% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  customerAppCompanyNameItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(17% - 40px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  customerAppServicesNeededItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(19% - 40px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  customerAppCommentItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(32% - 40px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    whiteSpace: "nowrap",
  },
  customerAppCreatedAtItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(15% - 40px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  customerAppStatusItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(17% - 40px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  customerAppItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "200px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  processingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "3px 8px",
    borderRadius: "40px 0px 0px 40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  processedStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "3px 8px",
    borderRadius: "40px 0px 0px 40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  downArrowBlock: {
    backgroundColor: "#F1F5F9",
    borderRadius: "0px 40px 40px 00px",
    border: "none",
  },
  supportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  customerAppCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fonWeight: 500,
    lineHeight: "22px",
    color: "#334155",
  },
  select: {
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E8FBFF",
      color: "#1A7BA4",
      fontWeight: "600",
    },
  },
  tabButton: (isActive: boolean) => ({
    fontWeight: isActive ? 700 : 400,
    color: isActive ? '#1A7BA4' : '#64748B',
    textTransform: 'none',
    padding: '8px 12px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
  }),
  tabsContainer: {
    borderBottom: '1px solid #E2E8F0',
    margin: '0 0 30px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
    '& .Mui-selected': {
      color: '#1A7BA4 !important',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1A7BA4 !important',
    }
  },
  showButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  contactFormTitle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  contactFormCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fonWeight: 500,
    lineHeight: "22px",
    color: "#334155",
  },
  contactFormsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  contactFormsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  headerContactFormNameTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(17% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormServicesNeededTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(19% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormCommentTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(32% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormCreatedAtTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(15% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormEmailTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(17% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormPhoneTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(17% - 40px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "180px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  contactFormsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
  },
  contactFormNameItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(14% - 30px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormServicesNeededItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(18% - 30px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormCommentItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(18% - 30px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    whiteSpace: "nowrap",
  },
  contactFormCreatedAtItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(14% - 30px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormEmailItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(18% - 30px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormPhoneItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(18% - 30px)",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "180px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalBox: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    height: "80vh",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
  },
  modalScrollBox: {
    mb: 2,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY: "scroll",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "32px",
    textAlign: "left",
    flex: 1,
    color: "0F172A",
    marginTop: "20px",
  },
  modalTitleBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
    marginTop: "-12px",
    borderBottom: "1px solid #CBD5E1",
  },
  modalTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#000000",
  },
  modalCloseButton: {
    color: "#64748B",
    border: "none",
    backgroundColor: "#F8FAFC",
    borderRadius: '100%',
    width: '56px',
    height: '56px',
    cursor: 'pointer',
  },
  modalDoubleBlock: {
    display: "flex",
    flexDirection: "row",
  },
  modalBlock: {
    display: "flex",
    margin: "8px 0px",
    flexDirection: "column",
    flex: 1,
  },
  modalBlockLabel: {
    textTransform: "uppercase" as const,
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#64748B",
  },
  modalBlockValuee: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#64748B",
    margin: "4px 0px",
  },
};
// Customizable Area End
